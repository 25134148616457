import { atom } from "jotai";
import { loadable } from "jotai/utils";
import { FeatureFlag } from "../types/feature-flags";
import { QUOTE_TAGS, POLICY_FILTERS } from "../constants/feature-flags";

export const asyncFeatureFlagsAtom = atom<Promise<FeatureFlag[]>>(
  async (_get) => {
    const response = await fetch(
      `${process.env.REACT_APP_API}/feature_flags/`,
      {
        method: "GET",
      },
    );
    const res = await response.json();
    return res.data;
  },
);

export const loadableFeatureFlagsAtom = loadable(asyncFeatureFlagsAtom);

const createFeatureFlagAtom = (feature: string) =>
  atom<boolean>((get) => {
    const loadableFeatureFlags = get(loadableFeatureFlagsAtom);
    if (loadableFeatureFlags.state !== "hasData") return false;
    const targetFeatureFlag = loadableFeatureFlags.data?.find(
      (ff) => ff.feature === feature,
    );
    if (!targetFeatureFlag) return false;
    return targetFeatureFlag.state;
  });

export const isQuoteTagsEnabledAtom = createFeatureFlagAtom(QUOTE_TAGS);
export const isPolicyFilterEnabledAtom = createFeatureFlagAtom(POLICY_FILTERS);
